import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../shared/Header';
import { Box, height, Stack } from '@mui/system';
import { Button, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Interview from '../Interview';
import { useLocation, useNavigate } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import MockInterview from '../Student/mock-interviews/MockInterview';
import { Margin } from '@mui/icons-material';
import DemoMockinterview from '../Student/mock-interviews/DemoMockinterview';

const AdminMockInterviewDashboard = () => {
	const [interviews, setInterviews] = useState([]);
	const [groupedInterviews, setGroupedInterviews] = useState([]);

	const [profileImage, setProfileImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const { state } = useLocation();
	const navigate = useNavigate();
	const fullName = `${firstName} ${lastName}`;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const getAllInterviews = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/student/mock-interviews`,
				{ search: searchTerm },
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			setGroupedInterviews(data.groupedInterviews);
			if (data.success) {
				console.log(data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		getAllInterviews();
	}, [searchTerm, state?.refetch]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	// New Search Functionality
	const filteredGroupedInterviews = groupedInterviews
		.map((group) => {
			// Filter mockInterviews within each group
			const filteredInterviews = group.mockInterviews.filter((interview) =>
				interview.job_role.toLowerCase().includes(searchTerm.toLowerCase())
			);

			// Only return groups that have at least one interview matching the search term
			if (filteredInterviews.length > 0) {
				return {
					...group,
					mockInterviews: filteredInterviews,
				};
			}

			// If no interviews match, return null (this will be filtered out later)
			return null;
		})
		.filter((group) => group !== null); // Filter out any null groups

	return (
		<>
			{/* <Toaster /> */}
			<Header />
			<Stack
				spacing={{ sm: 5, xs: 3, lg: 5 }}
				style={{
					backgroundColor: '#ecf7fa',
					width: '100%',
					minHeight: '100%',
					padding: '2vw 0vw 2vw 4vw',
					// margin: '2vw 0vw 2vw 4vw',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '90vw',
						marginRight: '25px',
					}}
				>
					<Typography
						fontWeight={'bold'}
						fontSize={isMobile ? '1.1rem' : '1.3rem'}
						style={{
							color: '#495057',
						}}
					>
						All Mock-Interviews
					</Typography>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '15px',
							width: '55vw',
							marginTop: isMobile ? '10px' : '',
						}}
					>
						<TextField
							variant="standard"
							label="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
							fullWidth={isMobile} // Full width on mobile
							InputProps={{
								endAdornment: (
									<SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
								),
							}}
							style={{
								marginBottom: '10px',
							}}
						/>
						<Button
							onClick={() => navigate('/mock-interviews/all-reports')}
							variant="outlined"
							size="large"
						>
							<Typography
								fontWeight="semibold"
								style={{
									color: '#224C84',
								}}
							>
								Interview Reports
							</Typography>
						</Button>
						<Button
							onClick={() => navigate('/create-mock-interviews')}
							variant="outlined"
							size="large"
						>
							<Typography
								fontWeight="semibold"
								style={{
									color: '#224C84',
								}}
							>
								Create Interview
							</Typography>
						</Button>
					</div>
				</div>
				<Stack
					direction={isMobile ? 'column' : 'row'}
					useFlexGap
					flexWrap={'wrap'}
					spacing={4} // This spacing controls the gap between the grouped interview sections
					alignItems={'center'}
					margin={10}
					justifyContent={'start'}
				>
					{loading && (
						<Stack spacing={5} justifyContent={'start'} direction={'row'}>
							{Array(3)
								.fill(null)
								.map((_, index) => (
									<Stack
										key={index}
										p={{ sm: 3, xs: 2 }}
										bgcolor={'#fff'}
										spacing={2}
										width={{ sm: '400px', xs: '100%' }}
										height={'auto'}
										borderRadius={'20px'}
										boxShadow={'8px 6px 12px 0 rgba(13,31,61,.04)'}
									>
										<Skeleton variant="text" width={'250px'} />
										<Skeleton variant="text" width={'200px'} />
										<Skeleton variant="text" />
										<Skeleton variant="button" width={'100px'} />
										<Skeleton variant="button" width={'100px'} />
										<Skeleton variant="button" width={'100px'} />
									</Stack>
								))}
						</Stack>
					)}

					{!loading && filteredGroupedInterviews?.length === 0 && (
						<Typography>No interviews available for you.</Typography>
					)}

					{!loading &&
						filteredGroupedInterviews?.map((group) => (
							<Box
								key={group.topicId}
								sx={{
									display: 'block',
									width: '90vw',
									mb: 4,
									justifyContent: 'start',
								}}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									style={{
										color: '#495057',
									}}
								>
									{group.topicName || 'No Topic'} {/* Display the topic name */}
								</Typography>
								<Stack
									direction={isMobile ? 'column' : 'row'}
									spacing={3} // Add spacing between the DemoMockinterview cards here
									sx={{
										display: 'flex',
										flexDirection: isMobile ? 'column' : 'row',
										justifyContent: isMobile ? 'center' : 'start',
										mt: isMobile ? 2 : 2,
										width: '90vw',
									}}
								>
									{group.mockInterviews.map((interview) => (
										<DemoMockinterview
											interviewDescription={interview.job_description}
											key={interview._id}
											interview_id={interview._id}
											pilot_id={interview.pilot_id}
											role={interview.job_role}
											poster={interview.job_poster}
											interview_level={interview.interview_level}
											questions={interview.questions}
											fullName={fullName}
											skills={interview.skills}
											refetch={getAllInterviews}
										/>
									))}
								</Stack>
							</Box>
						))}
				</Stack>
			</Stack>
		</>
	);
};

export default AdminMockInterviewDashboard;
