import React, { useEffect, useState } from 'react';
import {
	Button,
	Modal,
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { MuiChipsInput } from 'mui-chips-input';

const JobCreation = (props) => {
	const { details, setDetails } = props;
	console.log('details--', details);

	const [warning, setWarning] = useState('');
	const [imageFile, setImageFile] = useState(null);
	const [imagePreview, setImagePreview] = useState('');
	const [topics, setTopics] = useState([]);
	const [open, setOpen] = useState(false); // State for opening and closing modal
	const [topicName, setTopicName] = useState('');
	const [error, setError] = useState('');
	const [refetch, setRefetch] = useState(false);
	const handleTopicNameChange = (e) => {
		setTopicName(e.target.value);
	};

	const handleRefetch = () => {
		setRefetch((prev) => !prev);
	};

	useEffect(() => {
		// If editing, set the image file to null and set the preview from details.job_poster
		if (details.job_poster && !imageFile) {
			setImagePreview(details.job_poster); // Assuming job_poster is the URL
		}
	}, [details.job_poster, imageFile]);

	useEffect(() => {
		axios
			.get(`${API_URL}/labs/get-topics`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			})
			.then((res) => {
				console.log(res.data);
				setTopics(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [refetch]);

	const onChange = (e) => {
		const { name, value, files } = e.target;
		if (name === 'job_description' && value.length === 70) {
			setWarning('You have exceeded the maximum character limit of 70.');
		} else {
			setWarning('');
		}
		if (name === 'job_poster' && files && files[0]) {
			const file = files[0];
			setImageFile(file);
			setImagePreview(URL.createObjectURL(file));

			// Update the details state to include the image file
			setDetails((prevDetails) => ({
				...prevDetails,
				[name]: file,
			}));
		} else {
			setDetails((prevDetails) => ({
				...prevDetails,
				[name]: value,
			}));
		}
	};

	const removeImage = () => {
		setImageFile(null);
		setImagePreview('');
		setDetails((prevDetails) => ({
			...prevDetails,
			job_poster: '', // Clear the job_poster when image is removed
		}));
		document.getElementById('job_poster').value = null;
	};

	// Handle modal open and close
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// Handle submitting the new topic
	const handleSubmit = () => {
		if (!topicName.trim()) {
			setError('Topic name is required.');
			return;
		}

		axios
			.post(
				`${API_URL}/labs/create-topic`,
				{ topicName },
				{
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.success) {
					// Add new topic to the list
					setTopics((prevTopics) => [...prevTopics, res.data.data]);
					setTopicName(''); // Reset the input field
					handleClose(); // Close the modal
					handleRefetch();
				}
			})
			.catch((err) => {
				console.log('Error creating topic:', err);
			});
	};

	const handleCancel = () => {
		setTopicName(''); // Reset the topic name when canceling
		setError('');
		handleClose(); // Close the modal
	};

	return (
		<Stack gap={2}>
			<TextField
				label={'Job Role'}
				name="job_role"
				value={details.job_role}
				onChange={onChange}
				style={{
					width: '610px',
				}}
			/>

			<TextField
				label={'Interview Description'}
				name="job_description"
				value={details.job_description}
				onChange={onChange}
				fullWidth
				multiline
				rows={2}
				inputProps={{ maxLength: 70 }} // Set the maximum number of characters
			/>
			{warning && (
				<Typography color="error" variant="body2">
					{warning}
				</Typography>
			)}
			<div style={{ display: 'flex', gap: '10px' }}>
				<FormControl style={{ width: '100%' }}>
					<InputLabel id="topicId">Topics</InputLabel>
					<Select
						labelId="topicId"
						id="topicId"
						name="topicId"
						value={details.topicId || ''}
						label="Topics"
						onChange={onChange}
					>
						{topics.map((topic) => (
							<MenuItem key={topic._id} value={topic._id}>
								{topic.topicName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<IconButton onClick={handleOpen}>
					<AddCircleIcon style={{ fontSize: '40px', color: '#1976d2' }} />
				</IconButton>
			</div>
			<MuiChipsInput
				style={{
					maxWidth: '610px',
				}}
				label="Skills"
				name="skills"
				value={details.skills}
				helperText={details.skills?.length > 0 && 'Double click to edit sub skill'}
				onChange={(newChips) => {
					setDetails((prev) => {
						return {
							...prev,
							skills: newChips,
						};
					});
				}}
			/>
			<FormControl fullWidth>
				<InputLabel id="interview_level">Interview Level</InputLabel>
				<Select
					labelId="interview_level"
					id="interview_level"
					name="interview_level"
					value={details.interview_level || ''}
					label="Interview Level"
					onChange={onChange}
				>
					<MenuItem value={'Beginner'}>Beginner</MenuItem>
					<MenuItem value={'Intermediate'}>Intermediate</MenuItem>
					<MenuItem value={'Advanced'}>Advanced</MenuItem>
				</Select>
			</FormControl>
			<div style={{ marginTop: '10px', position: 'relative', width: '400px' }}>
				<label htmlFor="job_poster" style={{ cursor: 'pointer' }}>
					<div
						id="image-upload-box"
						style={{
							width: '400px',
							height: '300px',
							backgroundColor: '#eee',
							borderRadius: '25px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							overflow: 'hidden',
						}}
					>
						{imagePreview ? (
							<img
								src={imagePreview}
								alt="Banner Preview"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '25px',
								}}
							/>
						) : (
							<span style={{ color: '#555' }}>Upload Job Poster</span>
						)}
					</div>
				</label>
				<input
					name="job_poster"
					id="job_poster"
					type="file"
					onChange={onChange}
					style={{ display: 'none' }}
				/>
				<IconButton
					onClick={removeImage}
					style={{
						position: 'absolute',
						top: '10px',
						right: '10px',
						backgroundColor: 'white',
					}}
				>
					<CloseIcon />
				</IconButton>
			</div>

			{/* Add Topic Modal */}
			<Modal open={open} onClose={!open}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 500, // Adjust modal width if needed
						bgcolor: 'background.paper',
						borderRadius: '8px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography variant="h6" component="h2" gutterBottom>
						Add New Topic
					</Typography>
					<Stack spacing={2}>
						<TextField
							label="Topic Name"
							value={topicName}
							onChange={handleTopicNameChange}
							error={Boolean(error)}
							helperText={error}
							sx={{ width: '100%' }} // Set the TextField width to 100%
						/>
						<Stack direction="row" justifyContent={'center'} spacing={2}>
							<Button variant="outlined" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="contained" onClick={handleSubmit}>
								Submit
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Modal>
		</Stack>
	);
};

export default JobCreation;
