import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../../shared/Header';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import {
	Grid,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ProfileIcon from './images/porfile-icon.png';
import EmailIcon from './images/email-icon.png';
import CollegeIcon from './images/colleg-icon.png';
import PhoneIcon from './images/phone-icon.png';
import CompleteIcon from './images/complete-icon.png';
import PercentageIcon from './images/percentage-icon.png';
import BestIcon from './images/best-icon.png';
import BadgeIcon from './images/star-medal.png';
import AvgTimeIcon from './images/avg-time-icon.png';
import TimeIcon from './images/time-icon.png';
import { styled } from '@mui/material/styles';

const DemoPaper = styled(Paper)(({ theme }) => ({
	// width: 120,
	// height: 120,
	padding: theme.spacing(2),
	...theme.typography.body2,
	textAlign: 'center',
	borderRadius: '15px',
}));

const StatCard = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	textAlign: 'center',
	// background: '#f5f5f5',
	borderRadius: '12px',
	minWidth: '150px',
}));

ChartJS.register(ArcElement, Tooltip, Legend);

const StudentProfile = () => {
	const [totalInterviews, setTotalInterviews] = useState(0);
	const [interviewsGiven, setInterviewsGiven] = useState(0);
	const [incompleteInterviews, setIncompleteInterviews] = useState(0);
	const [pendingInterviews, setPendingInterviews] = useState(0);

	// const remainingInterviews = totalInterviews - interviewsGiven;

	const data = {
		labels: ['Given', 'Pending', 'Incomplete'],
		datasets: [
			{
				label: 'Mock Interviews',
				data: [interviewsGiven, pendingInterviews, incompleteInterviews],
				backgroundColor: ['green', 'rgb(54, 162, 235)', 'red'],
				hoverOffset: 4,
			},
		],
	};

	const config = {
		type: 'doughnut',
		data: data,
		options: {
			plugins: {
				tooltip: {
					callbacks: {
						label: function (context) {
							const label = context.dataset.label || '';
							const dataLabel = context.label || '';
							const value = context.raw || 0;
							return `${dataLabel}: ${value}`;
						},
					},
				},
			},
		},
	};

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [college, setCollege] = useState('');
	const [profileImage, setProfileImage] = useState('');
	const fullName = `${firstName} ${lastName}`;
	const [loading, setLoading] = useState(false);

	// Mock interview stats
	const [totalCompletedInterviews, setTotalCompletedInterviews] = useState(0);
	const [avgRating, setAvgRating] = useState(0);
	const [bestPerformance, setBestPerformance] = useState('');
	const [bestPerformanceScore, setBestPerformanceScore] = useState();
	const [timeSpent, setTimeSpent] = useState({ hours: 0, minutes: 0 });
	const [averageTimeSpent, setAverageTimeSpent] = useState({ hours: 0, minutes: 0 });
	const [interviewData, setInterviewData] = useState([]);

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/details`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			console.log(data, 'data student');
			setFirstName(data.studentDetails.first_name);
			setLastName(data.studentDetails.last_name);
			setEmail(data.studentDetails.email);
			setContact(data.studentDetails.contact);
			setCollege(data.studentDetails.college);
			setProfileImage(data.studentDetails.profile_image);
			console.log(data);
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	const getStudentStats = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/stats`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			console.log(data, 'student stats');
			setTotalInterviews(data.totalMockInterviews);
			setInterviewsGiven(data.givenMockInterviewsCount);
			setPendingInterviews(data.pendingInterviews);
			setAvgRating(Math.round(data.averageScore));
			setTotalCompletedInterviews(data.completedCount);
			setBestPerformanceScore(data.highestScoreInterview.overall_score);
			setBestPerformance(data.highestScoreInterview.interview_name);
			setTimeSpent(data.totalTimeSpent || { hours: 0, minutes: 0 });
			setAverageTimeSpent(data.averageTimeSpent || { hours: 0, minutes: 0 });
			setIncompleteInterviews(data.incompleteMockInterview);
			// Process the received data
			const processedInterviewData = data.attempts.map((attempt) => ({
				name: attempt.interviewName,
				attempts: attempt?.attemptsCount,
				avgRating: attempt?.averageJobScore || 'N/A', // Handle null averageJobScore
				bestRating: attempt?.bestScore || 'N/A', // Handle null highestJobScore
			}));

			// Update the state with the processed data
			setInterviewData(processedInterviewData);
			console.log(data);
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		getDetails();
		getStudentStats();
	}, []);

	const formatTimeSpent = (time) => {
		if (time.hours > 0) {
			return `${time.hours} hrs`;
		} else {
			return `${time.minutes} mins`;
		}
	};

	return (
		<>
			<Toaster />
			<Header fullName={fullName} />
			<div
				className="student-profile"
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '50px',
					backgroundColor: '#ECF7FA',
					// padding: '20px'
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '20px',
						width: '100%',
						maxWidth: '1000px',
						padding: '20px',
					}}
				>
					<DemoPaper square={false}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								padding: '20px 35px',
								gap: '50px',
							}}
						>
							<img
								style={{ borderRadius: '50%', width: '150px' }}
								src={profileImage}
								alt="profile"
							/>
							<div
								style={{
									marginLeft: '20px',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'start',
									textAlign: 'start',
								}}
							>
								<h1> {fullName}</h1>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '10px',
									}}
								>
									<img
										style={{ width: '30px', height: '30px' }}
										src={EmailIcon}
										alt="email"
									/>
									<h3>{email}</h3>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '10px',
									}}
								>
									<img
										style={{ width: '30px', height: '30px' }}
										src={CollegeIcon}
										alt="college"
									/>
									<h3>{college}</h3>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '10px',
									}}
								>
									<img
										style={{ width: '30px', height: '30px' }}
										src={PhoneIcon}
										alt="phone"
									/>
									<h3>{contact}</h3>
								</div>
							</div>
							{/* <img style={{ borderRadius: '50%', width: '250px', }} src={ProfileIcon} alt="icon" /> */}
						</div>
					</DemoPaper>
					<DemoPaper square={false}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '25px',
								gap: '10px',
							}}
						>
							<Typography variant="h6">Unlock your first badge</Typography>
							<img
								style={{ width: '150px', filter: 'grayscale(100%)' }}
								src={BadgeIcon}
								alt="medal icon"
							/>
							<Typography
								variant="h6"
								style={{ filter: 'grayscale(100%)', color: 'gray' }}
							>
								Data Engineer
							</Typography>
						</div>
					</DemoPaper>
				</div>

				{/* Interview Stats Section */}
				<div
					className="interview-stats"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'start',
						textAlign: 'start',
						alignItems: 'start',
						marginTop: '30px',
					}}
				>
					<Typography variant="h4" gutterBottom>
						Interview Stats
					</Typography>
					<Grid
						container
						spacing={3}
						style={{ maxWidth: '1000px', textAlign: 'center', marginTop: '1px' }}
					>
						<Grid item xs={12} sm={6} md={3} style={{ height: 'auto' }}>
							<StatCard elevation={3} style={{ height: '100%' }}>
								<Typography variant="h4">
									<img src={CompleteIcon} alt="completeicon" />
								</Typography>
								<Typography variant="h4">{totalCompletedInterviews}</Typography>
								<Typography variant="h6">Interviews Completed</Typography>
							</StatCard>
						</Grid>
						<Grid item xs={12} sm={6} md={3} style={{ height: 'auto' }}>
							<StatCard elevation={3} style={{ height: '100%' }}>
								<Typography variant="h4">
									<img src={PercentageIcon} alt="ratingicon" />
								</Typography>
								<Typography variant="h4">{avgRating}%</Typography>
								{/* <Typography variant="body2">Out of 5</Typography> */}
								<Typography variant="h6">Total Average Percentage</Typography>
							</StatCard>
						</Grid>
						{/* <Grid item xs={12} sm={6} md={3} style={{ height: 'auto' }}>
                            <StatCard elevation={3} style={{ height: '100%', alignItems: 'center', }}>
                                <Typography variant="h4">
                                    <img src={BestIcon} alt="besticon" />
                                </Typography>
                                <Typography variant="body2" style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                                    <span style={{ display: 'inline-block', maxWidth: '50%', overflowWrap: 'break-word',whiteSpace: 'normal' }}>
                                        {bestPerformance}
                                    </span>
                                    - {bestPerformanceScore}/5
                                </Typography>
                                <Typography variant="h6">Best Performance</Typography>
                            </StatCard>
                        </Grid> */}
						<Grid item xs={12} sm={6} md={3} style={{ height: 'auto' }}>
							<StatCard elevation={3} style={{ height: '100%' }}>
								<Typography variant="h4">
									<img src={TimeIcon} alt="timeicon" />
								</Typography>
								<Typography variant="h4">{formatTimeSpent(timeSpent)}</Typography>
								<Typography variant="h6">Total Time Spent</Typography>
							</StatCard>
						</Grid>
						<Grid item xs={12} sm={6} md={3} style={{ height: 'auto' }}>
							<StatCard elevation={3} style={{ height: '100%' }}>
								<Typography variant="h4">
									<img src={AvgTimeIcon} alt="timeicon" />
								</Typography>
								<Typography variant="h4">
									{formatTimeSpent(averageTimeSpent)}
								</Typography>
								<Typography variant="h6">Avg Time Spent Per Interview</Typography>
							</StatCard>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={3}
						style={{ textAlign: 'center', marginTop: '30px', marginBottom: '40px' }}
					>
						<Grid item>
							<DemoPaper elevation={3} style={{ height: '100%', padding: '16px' }}>
								<Typography variant="h5">Interviews</Typography>
								{data && data.datasets && data.datasets.length > 0 ? (
									<Doughnut data={data} options={config} />
								) : (
									<Typography variant="body1">No data available</Typography>
								)}
							</DemoPaper>
						</Grid>
						<Grid item style={{ width: '645px' }}>
							<DemoPaper style={{ height: '360px' }}>
								<Typography variant="h5">Interview Ratings</Typography>
								<TableContainer component={Paper} style={{ marginTop: '2px' }}>
									<Table aria-label="interview rating table">
										<TableHead>
											<TableRow>
												<TableCell sx={{ fontWeight: 'bold' }}>
													Interview Name
												</TableCell>
												<TableCell
													align="center"
													sx={{ fontWeight: 'bold' }}
												>
													No. of Attempts
												</TableCell>
												<TableCell
													align="center"
													sx={{ fontWeight: 'bold' }}
												>
													Average Percentage
												</TableCell>
												<TableCell
													align="center"
													sx={{ fontWeight: 'bold' }}
												>
													Best Percentage
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{interviewData.length > 0 ? (
												interviewData.map((row) => (
													<TableRow key={row.id}>
														{' '}
														{/* Ensure 'id' is unique */}
														<TableCell component="th" scope="row">
															{row.name}
														</TableCell>
														<TableCell align="center">
															{row.attempts}
														</TableCell>
														<TableCell align="center">
															{row.avgRating}%
														</TableCell>
														<TableCell align="center">
															{row.bestRating}%
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={4} align="center">
														No data available
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</DemoPaper>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
};

export default StudentProfile;
